define("discourse/plugins/discourse-mail-daily-summary/discourse/templates/connectors/user-preferences-emails/mail-daily-summary", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.mail_daily_summary_enabled}}
    <div class="control-group">
      <label class="control-label">{{i18n 'mail_daily_summary.daily'}}</label>
      {{preference-checkbox labelKey="mail_daily_summary.preference_label" checked=model.custom_fields.user_mlm_daily_summary_enabled}}
      {{log this}}
      <div class='instructions'>{{{i18n 'mail_daily_summary.instructions'}}}</div>
    </div>
  {{/if}}
  
  */
  {
    "id": "AxrfTXF1",
    "block": "[[[41,[30,0,[\"siteSettings\",\"mail_daily_summary_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,3],[\"mail_daily_summary.daily\"],null]],[13],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"labelKey\",\"checked\"],[\"mail_daily_summary.preference_label\",[30,0,[\"model\",\"custom_fields\",\"user_mlm_daily_summary_enabled\"]]]]]],[1,\"\\n    \"],[1,[54,[[30,0]]]],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[2,[28,[37,3],[\"mail_daily_summary.instructions\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-mail-daily-summary/discourse/templates/connectors/user-preferences-emails/mail-daily-summary.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-mail-daily-summary/discourse/templates/connectors/user-preferences-emails/mail-daily-summary.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"div\",\"label\",\"i18n\",\"preference-checkbox\",\"log\"]]",
    "moduleName": "discourse/plugins/discourse-mail-daily-summary/discourse/templates/connectors/user-preferences-emails/mail-daily-summary.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});